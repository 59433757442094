import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import * as ReactGA from "react-ga";

const NoNetworkIcon = () => (
  <svg
    id="bold"
    enableBackground="new 0 0 24 24"
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#1f2a44"
        d="m12 24c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z"
      />
    </g>
    <g>
      <path
        fill="#1f2a44"
        d="m4.224 20.776c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l15.552-15.552c.391-.391 1.023-.391 1.414 0s.391 1.024 0 1.414l-15.552 15.552c-.196.196-.452.293-.707.293z"
      />
    </g>
    <g>
      <path
        fill="#fc4c02"
        d="m13.174 13.655-3.25 3.25c.411.729 1.183 1.229 2.076 1.229 1.319 0 2.394-1.077 2.394-2.4 0-.894-.496-1.666-1.22-2.079z"
      />
    </g>
    <g>
      <path
        fill="#fc4c02"
        d="m7.784 13.359c.105-.105.216-.202.327-.298l3.439-3.439c-1.956.11-3.784.92-5.183 2.326-.39.392-.388 1.025.004 1.415.39.389 1.025.387 1.413-.004z"
      />
    </g>
    <g>
      <path
        fill="#fc4c02"
        d="m16.086 10.743-1.471 1.471c.584.288 1.126.669 1.601 1.145.195.196.452.294.709.294.255 0 .511-.097.705-.291.392-.39.394-1.023.004-1.415-.47-.472-.993-.869-1.548-1.204z"
      />
    </g>
    <g>
      <path
        fill="#fc4c02"
        d="m18.775 8.054-1.43 1.43c.534.357 1.037.769 1.5 1.234.195.196.452.294.709.294.255 0 .511-.097.705-.292.392-.39.394-1.022.004-1.414-.463-.465-.965-.876-1.488-1.252z"
      />
    </g>
    <g>
      <path
        fill="#fc4c02"
        d="m5.154 10.718c1.83-1.839 4.261-2.851 6.846-2.851.412 0 .818.035 1.22.086l1.703-1.703c-.945-.244-1.922-.383-2.923-.383-3.12 0-6.055 1.222-8.264 3.44-.39.392-.388 1.024.004 1.414.391.39 1.026.388 1.414-.003z"
      />
    </g>
  </svg>
);
const OfflinePage = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: "100vh", textAlign: "center" }}
  >
    {ReactGA.event({
      category: "No Network",
      action: "No Network",
    })}
    <div style={{ maxWidth: 300, margin: "10px 40px" }}>
      <NoNetworkIcon />
    </div>
    <Typography
      style={{
        maxWidth: 400,
      }}
      gutterBottom
      variant={"h5"}
    >
      No network connectivity!
    </Typography>
    <Typography
      style={{
        maxWidth: 400,
      }}
      variant={"body1"}
    >
      If you're having issues contact support@wrcfan.com
    </Typography>
    <Button
      style={{
        margin: 20,
      }}
      onClick={() => window.location.reload()}
      variant={"outlined"}
    >
      RELOAD PAGE
    </Button>
  </Grid>
);

export default OfflinePage;
