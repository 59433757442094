import React, { useEffect } from "react";

import { withFirebase } from ".";
import AuthUserContext from "../Firebase/authUserContext";
import { compose } from "react-recompose";
import * as ROUTES from "../../constants/routes";
import { withRouter } from "../../withRouter";
import { Navigate, useNavigate } from "react-router-dom";

const WithAuthorization = (condition) => (Component) =>
  compose(
    withRouter,
    withFirebase
  )(function WithAuthorization(props) {
    const authUser = props.firebase.auth.currentUser;
    const hasProfile = JSON.parse(localStorage.getItem("hasProfile"));

    const navigate = useNavigate();

    useEffect(() => {
      if (
        authUser &&
        hasProfile === false &&
        props.location.pathname !== ROUTES.PROFILE
      ) {
        if (!window.previousLocation) {
          window.previousLocation = props.location;
        }
        navigate(ROUTES.PROFILE);
      } else if (condition && authUser === null) {
        window.previousLocation = props.location;
      }
    });

    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          !condition || condition(authUser) ? (
            <Component {...props} authUser={authUser} />
          ) : (
            <Navigate
              to={
                !AuthRequiredCondition(authUser) ? ROUTES.SIGN_IN : ROUTES.HOME
              }
            ></Navigate>
          )
        }
      </AuthUserContext.Consumer>
    );
  });

const AuthRequiredCondition = (authUser) => authUser != null;
const AdminRequiredCondition = (authUser) =>
  AuthRequiredCondition(authUser) &&
  authUser?.tokenResult?.claims?.groups?.indexOf("Admin") >= 0;

export { AuthRequiredCondition, AdminRequiredCondition };
export default WithAuthorization;
