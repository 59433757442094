import { axiosInstance as axios } from "../utils/axioUtils";

/**
 * Returns 404 if no event is found. Occurs only at the end of the season.
 */
export const getProfile = () =>
  axios.get(`/profile`).then((response) => response.data);

export const putProfile = (newProfile) => axios.put(`/profile`, newProfile);

export const unsubscribe = (a, b, type) =>
  axios.put(`/emails/unsubscribe`, null, {
    params: {
      a,
      b,
      type,
    },
  });

export const getDonations = () =>
  axios.get(`/profile/donations`).then((response) => response.data);
