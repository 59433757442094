import { CURRENT_SEASON } from "../constants/misc";

export const setSeason = (season) => {
  !!season
    ? sessionStorage.setItem("wrcfan-season", season)
    : sessionStorage.removeItem("wrcfan-season");
  window.location.reload();
};

export const getSeason = () =>
  sessionStorage.getItem("wrcfan-season") || CURRENT_SEASON;

export const isHistoryViewActive = () =>
  !!sessionStorage.getItem("wrcfan-season");
