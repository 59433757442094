export const LANDING = "/";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const CONTACT = "/contact";
export const PREDICT = "/predict";
export const PRIVACY = "/privacy";
export const LEAGUES = "/leagues";
export const LEAGUES_NEW = "/leagues/new";
export const LEAGUES_FORBIDDEN = "/leagues/forbidden";
export const PROFILE = "/profile";
export const LEADERBOARDS = "/leaderboards";
export const STATISTICS = "/statistics";
export const RULES = "/rules";
export const UNSUBSCRIBE = "/unsubscribe";
export const SUPPORT = "/support";
export const ADMIN = "/admin";
