import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";

/**
 * This is a hacky backwards compatibility tool for react-router v5->v6
 * TODO remove it.
 */
export const withRouter = (WrappedComponent) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  return (
    <WrappedComponent
      {...props}
      location={location}
      history={{
        location,
        push: navigate,
        replace: (to) => navigate(to, { replace: true }),
      }}
      match={{ params }}
    />
  );
};
