import axios from "axios";
import * as ReactGA from "react-ga";
import { getSeason, isHistoryViewActive } from "./seasonUtil";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

export const setUpInterceptors = (authUser) => {
  if (authUser) {
    axiosInstance.interceptors.request.use(async (config) => {
      await checkAndRefreshToken(authUser);
      config.headers["Authorization"] = "Bearer " + authUser.tokenResult.token;
      if (isHistoryViewActive()) config.headers["Season"] = getSeason();
      return config;
    });
  } else {
    axiosInstance.interceptors.request.handlers.forEach((_, index) => {
      axiosInstance.interceptors.request.eject(index);
    });
  }
};

// Alternative approach: https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
const checkAndRefreshToken = async (authUser) => {
  const tokenResult = authUser.tokenResult;
  const expirationDate = new Date(tokenResult.expirationTime);
  if (expirationDate <= new Date()) {
    ReactGA.event({
      category: "Token",
      action: "Refresh",
      label: authUser.uid,
    });
    authUser.tokenResult = await authUser.getIdTokenResult(true);
  }
};
