import React from "react";

import { withFirebase } from ".";
import * as ROUTES from "../../constants/routes";
import AuthUserContext from "../Firebase/authUserContext";
import { compose } from "react-recompose";
import * as ReactGA from "react-ga";
import FullScreenCircularProgress from "../Progress/progress";
import Bugsnag from "@bugsnag/js";
import { setUpInterceptors } from "../../utils/axioUtils";
import { getProfile } from "../../service/profileService";
import { onAuthStateChanged } from "firebase/auth";
import { withRouter } from "../../withRouter";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    state = {};

    constructor(props) {
      super(props);
      if (!localStorage) return;
      localStorage.removeItem("hasProfile"); // do not remove
    }

    componentDidMount() {
      this.listener = onAuthStateChanged(
        this.props.firebase.auth,
        async (authUser) => {
          if (authUser) {
            authUser.tokenResult = await authUser.getIdTokenResult();
            ReactGA.set({ userId: authUser.uid });
            Bugsnag.setUser(authUser.uid, authUser.email, authUser.displayName);
          } else {
            Bugsnag.setUser(undefined);
            localStorage.removeItem("hasProfile");
            this.setState({ authUser: null, hasProfile: null });
          }
          setUpInterceptors(authUser);
          this.setState({ authUser });
          if (authUser) {
            this.enforceProfile();
          }
        }
      );
    }

    enforceProfile = () => {
      let hasProfile = false;
      getProfile()
        .then((profile) => {
          sessionStorage.setItem("wrcfan-supporter", profile.isSupporter);
          // has profile
          hasProfile = true;
          if (this.props.history.location.pathname === ROUTES.SIGN_IN) {
            if (window.previousLocation) {
              this.props.history.push(window.previousLocation);
            } else {
              this.props.history.push(ROUTES.HOME);
            }
          }
        })
        .catch((error) => {
          // has no profile
          if (
            !error.isAxiosError ||
            !error.response ||
            error.response.status !== 404
          ) {
            throw error;
          }
          hasProfile = false;
          if (!window.previousLocation) {
            window.previousLocation = this.props.location;
          }
          this.props.history.push(ROUTES.PROFILE);
        })
        .finally(() => {
          localStorage.setItem("hasProfile", JSON.stringify(hasProfile));
          this.setState({ hasProfile });
        });
    };

    componentWillUnmount() {
      if (this.listener !== undefined) this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          {this.state.authUser !== undefined ? (
            <Component {...this.props} />
          ) : (
            <FullScreenCircularProgress />
          )}
        </AuthUserContext.Provider>
      );
    }
  }

  return compose(withRouter, withFirebase)(WithAuthentication);
};

export default withAuthentication;
