import React from "react";
import FullScreenCircularProgress from "./components/Progress/progress";
import * as ReactGA from "react-ga";

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  componentDidMount() {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_VERSION;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        this.setState({ loading: false, isLatestVersion: !shouldForceRefresh });
      })
      .catch(() => {
        this.setState({ loading: false, isLatestVersion: false });
      });
  }
  render() {
    const { loading, isLatestVersion } = this.state;
    if (loading) {
      return <FullScreenCircularProgress />;
    } else if (!isLatestVersion) {
      console.log("App is outdated. Force reload");
      ReactGA.event({
        category: "Cache",
        action: "Reloaded app",
      });
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        window.location.reload();
      }
      return null;
    } else {
      return this.props.children;
    }
  }
}

export default CacheBuster;
