import React from "react";

import { Grid, CircularProgress } from "@mui/material";

const FullScreenCircularProgress = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item xs={3}>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default FullScreenCircularProgress;
