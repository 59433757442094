import "core-js/stable";
import "react-app-polyfill/ie11";
import "indexeddb-getall-shim";
import React, { Component, lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "typeface-roboto";
import { Firebase, FirebaseContext } from "./components/Firebase";
import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import FullScreenCircularProgress from "./components/Progress/progress";
import ErrorPage from "./components/Error/errorPage";
import * as ReactGA from "react-ga";
import retry from "./utils/retry";
import CacheBuster from "./cacheBuster";
import * as serviceWorker from "./serviceWorker";
import OfflinePage from "./components/Error/offline";
import { isHistoryViewActive } from "./utils/seasonUtil";

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ["production"],
  appVersion: process.env.REACT_APP_VERSION,
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = bugsnagClient.getPlugin("react");

class CustomErrorBoundary extends Component {
  state = {};

  componentDidMount() {
    const hasSetter =
      Object.getOwnPropertyDescriptor(window, "onunhandledrejection") &&
      typeof Object.getOwnPropertyDescriptor(window, "onunhandledrejection")
        .set === "function";
    if (hasSetter) {
      window.onunhandledrejection = (e) => {
        // TODO do not notify these errors in Bugsnag
        this.setState({
          hasNetworkError: e.reason.message === "Network Error",
        });
        // TODO show ErrorPage for all other unhandledrejections!
      };
      ReactGA.event({
        category: "Error",
        action: "unhandledrejection",
        label: "registered",
      });
    } else {
      ReactGA.event({
        category: "Error",
        action: "unhandledrejection",
        label: "not registered",
      });
    }
  }

  render() {
    return (
      <ErrorBoundary
        onError={(event) => {
          const error = event.errors[0];
          ReactGA.event({
            category: "Error",
            action: error.errorClass,
            label: error.errorMessage,
          });
          return error.errorMessage !== "Network Error";
        }}
        FallbackComponent={ErrorPage}
      >
        {this.state.hasNetworkError ? <OfflinePage /> : this.props.children}
      </ErrorBoundary>
    );
  }
}

const App = lazy(() => retry(() => import("./components/App/app")));

const muiTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: isHistoryViewActive() ? "#4d4d4d" : "#1f2a44",
      },
      secondary: {
        main: "#fc4c02",
      },
    },
    table: {
      header: isHistoryViewActive() ? "#4d4d4d" : "rgb(75, 84, 105)",
      headerText: "#fff",
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiTab: {
        styleOverrides: {
          textColorInherit: {
            opacity: 0.7,
          },
        },
      },
    },
  })
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <CustomErrorBoundary>
    <FirebaseContext.Provider value={new Firebase()}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <CacheBuster>
            <BrowserRouter>
              <Suspense fallback={<FullScreenCircularProgress />}>
                <App />
              </Suspense>
            </BrowserRouter>
          </CacheBuster>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </FirebaseContext.Provider>
  </CustomErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
